<script setup lang="ts">
import AppAvatar from "@/Components/Shared/avatar/AppAvatar.vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppText from "@/Components/Shared/text/AppText.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import AppBadge from "@/Components/Shared/badge/AppBadge.vue";
import AppBadgeGroup from "@/Components/Shared/badge/AppBadgeGroup.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";
import { PostTypeEnum, PostTypeEnumValues } from "@/Enums/PostTypeEnum";
import AppUserPopover from "@/Components/Shared/user/AppUserPopover.vue";
import AppDropdown from "@/Components/Shared/dropdown/AppDropdown.vue";
import AppMenu from "@/Components/Shared/menu/AppMenu.vue";
import AppMenuItem from "@/Components/Shared/menu/AppMenuItem.vue";
import {computed} from "vue";
import {isCurrentUserById, isGuest, isLoggedIn, isVerified, mustBeLogin, mustBeVerified} from "@/Utils/Helpers";
import TheFlagForm from "@/Components/App/forms/TheFlagForm.vue";
import useEmitter from "@/Composables/useEmitter";
import AppBookmarkButton from "@/Components/App/bookmarks/AppBookmarkButton.vue";

type Props = {
    post: object;
};

const { post } = defineProps<Props>();

const isOwner = computed(() => {
    return isLoggedIn && post.author
        ? isCurrentUserById(post.author.id)
        : false;
});
const {emitter} = useEmitter();

const flag = () => {
    if (isGuest.value) {
        mustBeLogin();
        return;
    }

    if (!isVerified.value) {
        mustBeVerified();
        return;
    }

    emitter.emit("modal", {
        component: TheFlagForm,
        title: "Post rapporteren",
        props: {
            itemId: post.id,
            path: post.path,
            type: "post",
        },
    });
};
</script>

<template>
    <div :class="['relative flex flex-col bg-white border rounded',
        {
            'border-primary/50': post.subType === PostTypeEnum.TCGERS,
        },
    ]">
        <AppLink :href="post.path" class="relative flex flex-col sm:flex-row bg-white gap-2 lg:gap-4 hover:no-underline hover:bg-slate-50 py-4 px-4 lg:px-6">
            <AppUserPopover
                v-if="post.author"
                :user-id="post.author.id"
            >
                <AppLink :href="post.author.path">
                    <AppAvatar
                        :src="post.author.avatar"
                        size="small"
                    />
                </AppLink>
            </AppUserPopover>

            <div class="flex flex-col grow gap-1">
                <div class="justify-between flex gap-1">
                    <AppUserPopover
                        v-if="post.author"
                        :user-id="post.author.id"
                    >
                        <AppLink
                            v-if="post.author"
                            :href="post.author.path"
                            size="small"
                        >
                            @{{ post.author.username }}
                        </AppLink>
                    </AppUserPopover>

                    <AppText
                        size="small"
                        color="grayLight"
                        as="span"
                    >
                        {{ post.created }}
                    </AppText>
                </div>
                <AppText
                    v-if="post.subject_names"
                    size="xsmall"
                    class="line-clamp-1 -mt-1"
                    color="grayLight"
                >
                    ↳ {{ post.subject_names }}
                </AppText>

                <AppTitle
                    as="h3"
                    size="small"
                >
                    {{ post.title }}
                </AppTitle>

                <AppText size="small" class="leading-relaxed">
                    {{ post.excerpt }}
                </AppText>
            </div>
        </AppLink>

        <AppButtonGroup class="border-t grow p-2 border-slate-100">
            <AppLink
                icon="comment"
                variant="buttonXxSmallGray"
                :href="`${post.path}#comments`"
                icon-size="small"
            >
                {{ post.good_comments_count || post.comments_count }}
            </AppLink>

            <AppBadgeGroup>
                <AppBadge
                    v-if="post.game"
                    :color="post.game.slug"
                >
                    {{ post.game.slug }}
                </AppBadge>

                <AppBadge
                    v-if="post.subType !== PostTypeEnum.GENERAL"
                    :color="
                        post.subType === PostTypeEnum.TCGERS
                            ? 'primary'
                            : 'light'
                    "
                >
                    {{ PostTypeEnumValues[post.subType] }}
                </AppBadge>
            </AppBadgeGroup>

            <AppDropdown
                :variant="['right', 'relative']"
                :caret="false"
                size="small"
                class="ml-auto"
            >
                <template #toggle>
                    <AppButton icon="dots" variant="ghost" size="small" icon-color="gray" />
                </template>

                <AppMenu>
                    <AppMenuItem v-if="isOwner">
                        <AppLink
                            :href="`/posts/edit/${post.id}`"
                            variant="buttonGhost"
                            class="align-left w-full"
                            icon="edit"
                            icon-size="xsmall"
                        >
                            Wijzig
                        </AppLink>
                    </AppMenuItem>
                    <template v-else>
                        <AppMenuItem>
                            <AppButton
                                variant="ghost"
                                align="left"
                                icon="flag"
                                icon-size="xsmall"
                                :size="['full', 'small']"
                                @click="flag"
                            >
                                Rapporteren
                            </AppButton>
                        </AppMenuItem>
                        <AppMenuItem>
                            <AppBookmarkButton
                                :path="post.path"
                                :bookmarked="post.bookmarked"
                                :button-size="['full', 'small']"
                            />
                        </AppMenuItem>
                    </template>
                </AppMenu>
            </AppDropdown>
        </AppButtonGroup>
    </div>
</template>
